import {
	INIT_DETAILS_PAGE,
	INIT_DETAILS_PAGE_MODAL,
	SUBMIT_LOCATION,
	SUBMIT_LOCATION_MODAL,
} from '../actionTypes';
import { setCurrentStep, setStepCompleted } from '../actions/funnel';
import {
	getCurrentMoveplan,
	getCurrentMoveplanModal,
	setMoveplanModalLoading,
	setCoiInfo,
	setCurrentMoveplanID,
	updateMovePlan,
	updateMovePlanModal,
	setMoveplanLoading,
} from '../actions/movePlan';
import { setDummyPayloadForMovePlan } from '../actions/common';
import { setDetailsLoading } from '../actions/details';
import { format } from 'date-fns';
import has from 'lodash-es/has';

const detailsMiddleware =
	({ getState, dispatch }) =>
	next =>
	action => {
		next(action);
		if (action.type === INIT_DETAILS_PAGE) {
			dispatch(setDetailsLoading(true));
			dispatch(setCurrentStep('DETAILS'));
			if (getState().movePlan.currentMPID !== action.payload.movePlanId) {
				dispatch(setCurrentMoveplanID(action.payload.movePlanId));
			}
			dispatch(getCurrentMoveplan(getState().movePlan.currentMPID));
		}

		if (action.type === INIT_DETAILS_PAGE_MODAL) {
			dispatch(setDetailsLoading(true));
			if (getState().movePlan.currentMPID !== action.payload) {
				dispatch(setCurrentMoveplanID(action.payload));
			}
			dispatch(getCurrentMoveplanModal(action.payload));
			dispatch(setMoveplanModalLoading(true));
		}

		if (action.type === SUBMIT_LOCATION) {
			dispatch(setMoveplanLoading(true));
			let formData = action.payload;
			let { details } = getState().movePlan.currentPlan;
			let payload = {
				...details,
				address_src: 'gmaps',
				extra_pick_up_enabled: formData.extra_pick_up_enabled,
				extra_drop_off_enabled: formData.extra_drop_off_enabled,
			};
			payload.pick_up = {
				street_address: formData.pick_up.street_address,
				apartment_number: !!formData.pick_up.apartment_number
					? formData.pick_up.apartment_number
					: null,
				city: formData.pick_up.city,
				state: formData.pick_up.state,
				zip: formData.pick_up.zip,
				geocoded_address: formData.pick_up.geocoded_address,
				height_id: parseInt(formData.pick_up.height.value, 10),
				certificate_of_insurance_required: !!formData.pick_up.coi,
				latitude: `${formData.pick_up.lat}`,
				longitude: `${formData.pick_up.lng}`,
			};
			if (!formData.storage_type.move_into_storage.value) {
				payload.drop_off = {
					street_address: formData.drop_off.street_address,
					apartment_number: !!formData.drop_off.apartment_number
						? formData.drop_off.apartment_number
						: null,
					city: formData.drop_off.city,
					state: formData.drop_off.state,
					zip: formData.drop_off.zip,
					geocoded_address: formData.drop_off.geocoded_address,
					height_id: parseInt(formData.drop_off.height.value, 10),
					certificate_of_insurance_required: !!formData.drop_off.coi,
				};
				if (has(details, 'drop_off')) {
					if (formData.drop_off.lat !== details.drop_off.latitude)
						payload.drop_off.lat = formData.drop_off.lat;
					else payload.drop_off.latitude = details.drop_off.latitude;
					if (formData.drop_off.lng !== details.drop_off.longitude)
						payload.drop_off.lng = formData.drop_off.lng;
					else payload.drop_off.longitude = details.drop_off.longitude;
				} else {
					payload.drop_off.lat = formData.drop_off.lat;
					payload.drop_off.lng = formData.drop_off.lng;
				}
			}
			if (!!formData.extra_pick_up_enabled) {
				payload.extra_pick_up = {
					street_address: formData.extra_pick_up.street_address,
					apartment_number: !!formData.extra_pick_up.apt
						? formData.extra_pick_up.apt
						: null,
					city: formData.extra_pick_up.city,
					state: formData.extra_pick_up.state,
					zip: formData.extra_pick_up.zip,
					geocoded_address: formData.extra_pick_up.geocoded_address,
					height_id: parseInt(formData.extra_pick_up.height.value, 10),
					certificate_of_insurance_required: !!formData.extra_pick_up.coi,
					latitude: `${formData.extra_pick_up.lat}`,
					longitude: `${formData.extra_pick_up.lng}`,
				};
				if (!!formData.extra_pick_up.elevator) {
					payload.extra_pick_up.elevator_start_time =
						details.extra_pick_up.elevator_start_time;
					payload.extra_pick_up.elevator_end_time =
						details.extra_pick_up.elevator_end_time;
				}
				if (!!formData.extra_pick_up.coi) {
					payload.extra_pick_up.management_contact_name =
						details.extra_pick_up.management_contact_name;
					payload.extra_pick_up.management_phone_number =
						details.extra_pick_up.management_phone_number;
				} else {
					dispatch(
						setCoiInfo({
							addressKey: 'extra_pick_up',
							certificate_of_insurance_required: false,
							management_contact_name: null,
							management_phone_number: null,
						})
					);
				}
			} else {
				delete payload.extra_pick_up;
			}

			if (formData.extra_drop_off_enabled) {
				payload.extra_drop_off = {
					street_address: formData.extra_drop_off.street_address,
					apartment_number: !!formData.extra_drop_off.apt
						? formData.extra_drop_off.apt
						: null,
					city: formData.extra_drop_off.city,
					state: formData.extra_drop_off.state,
					zip: formData.extra_drop_off.zip,
					geocoded_address: formData.extra_drop_off.geocoded_address,
					height_id: parseInt(formData.extra_drop_off.height.value, 10),
					certificate_of_insurance_required: !!formData.extra_drop_off.coi,
					latitude: `${formData.extra_drop_off.lat}`,
					longitude: `${formData.extra_drop_off.lng}`,
				};
				if (!!formData.extra_drop_off.elevator) {
					payload.extra_drop_off.elevator_start_time =
						details.extra_drop_off.elevator_start_time;
					payload.extra_drop_off.elevator_end_time =
						details.extra_drop_off.elevator_end_time;
				}
				if (!!formData.extra_drop_off.coi) {
					payload.extra_drop_off.management_contact_name =
						details.extra_drop_off.management_contact_name;
					payload.extra_drop_off.management_phone_number =
						details.extra_drop_off.management_phone_number;
				} else {
					dispatch(
						setCoiInfo({
							addressKey: 'extra_pick_up',
							certificate_of_insurance_required: false,
							management_contact_name: null,
							management_phone_number: null,
						})
					);
				}
			} else {
				delete payload.extra_drop_off;
			}

			if (formData.user_note !== getState().movePlan.currentPlan.user_note)
				payload.user_note = formData.user_note;
			if (
				(formData.contact_phone_number === '' &&
					getState().movePlan.currentPlan.contact_phone_number !== null) ||
				!!formData.contact_phone_number
			) {
				payload.contact_phone_number = formData.contact_phone_number;
			}
			if (
				(formData.contact_first_name === '' &&
					getState().movePlan.currentPlan.contact_first_name !== null) ||
				!!formData.contact_first_name
			) {
				payload.contact_first_name = formData.contact_first_name;
			}
			if (
				(formData.contact_last_name === '' &&
					getState().movePlan.currentPlan.contact_last_name !== null) ||
				!!formData.contact_last_name
			) {
				payload.contact_last_name = formData.contact_last_name;
			}
			if (!!formData.pick_up.elevator) {
				payload.pick_up.elevator_start_time =
					details.pick_up.elevator_start_time;
				payload.pick_up.elevator_end_time = details.pick_up.elevator_end_time;
			}
			if (!!formData.drop_off.elevator) {
				payload.drop_off.elevator_start_time =
					details.drop_off.elevator_start_time;
				payload.drop_off.elevator_end_time = details.drop_off.elevator_end_time;
			}
			if (!!formData.pick_up.coi) {
				payload.pick_up.management_contact_name =
					details.pick_up.management_contact_name;
				payload.pick_up.management_phone_number =
					details.pick_up.management_phone_number;
			}
			if (!!formData.drop_off.coi) {
				payload.drop_off.management_contact_name =
					details.drop_off.management_contact_name;
				payload.drop_off.management_phone_number =
					details.drop_off.management_phone_number;
			}
			if (formData.storage_enabled) {
				if (formData.storage_type.storage_in_transit.value) {
					payload.storage_move_out_date = format(
						formData.storage_move_out_date,
						'MM/DD/YYYY'
					);
					payload.warehouse_destination = false;
				} else {
					delete payload.drop_off;
					delete payload.storage_move_out_date;
					payload.warehouse_destination = true;
				}
				payload.move_into_storage =
					formData.storage_type.move_into_storage.value;
				payload.warehouse_destination =
					formData.storage_type.move_into_storage.value;
				payload.storage_in_transit =
					formData.storage_type.storage_in_transit.value;
			} else {
				payload.move_into_storage = false;
				payload.storage_in_transit = false;
				payload.storage_move_out_date = '';
				payload.warehouse_destination = false;
			}
			if (
				!!details['pick_up'].certificate_of_insurance_required !==
				formData['pick_up'].coi
			) {
				dispatch(
					setCoiInfo({
						addressKey: 'pick_up',
						certificate_of_insurance_required: formData['pick_up'].coi,
						management_contact_name:
							formData['pick_up'].management_contact_name,
						management_phone_number:
							formData['pick_up'].management_phone_number,
					})
				);
			}
			if (!!details['drop_off'] && formData['drop_off'].coi) {
				if (
					details['drop_off'].certificate_of_insurance_required !==
					formData['drop_off'].coi
				) {
					dispatch(
						setCoiInfo({
							addressKey: 'drop_off',
							certificate_of_insurance_required: formData['drop_off'].coi,
							management_contact_name:
								formData['drop_off'].management_contact_name,
							management_phone_number:
								formData['drop_off'].management_phone_number,
						})
					);
				}
			}
			if (!!formData.first_available_date) {
				payload.first_available_date = format(
					formData.first_available_date,
					'MM/DD/YYYY'
				);
			}
			dispatch(
				updateMovePlan(
					{
						address_update: true,
						email_lead: formData.email_lead || getState().auth.user?.email,
						move_plan: {
							...payload,
							contact_email_address:
								formData.email_lead || getState().auth.user?.email,
						},
					},
					true
				)
			);
			if (!getState().funnel.progressBarSteps.DETAILS.isCompleted) {
				dispatch(setStepCompleted('DETAILS'));
			}
			dispatch(setDetailsLoading(false));
		}
		if (action.type === SUBMIT_LOCATION_MODAL) {
			dispatch(setMoveplanLoading(true));
			let formData = action.payload;
			let { details } = getState().movePlan.currentPlan;
			let payload = {
				...details,
				address_src: 'gmaps',
				extra_pick_up_enabled: formData.extra_pick_up_enabled,
				extra_drop_off_enabled: formData.extra_drop_off_enabled,
			};
			payload.pick_up = {
				street_address: formData.pick_up.street_address,
				apartment_number: !!formData.pick_up.apartment_number
					? formData.pick_up.apartment_number
					: null,
				city: formData.pick_up.city,
				state: formData.pick_up.state,
				zip: formData.pick_up.zip,
				geocoded_address: formData.pick_up.geocoded_address,
				height_id: parseInt(formData.pick_up.height.value, 10),
				certificate_of_insurance_required: !!formData.pick_up.coi,
				latitude: `${formData.pick_up.lat}`,
				longitude: `${formData.pick_up.lng}`,
			};
			if (!formData.storage_type.move_into_storage.value) {
				payload.drop_off = {
					street_address: formData.drop_off.street_address,
					apartment_number: !!formData.drop_off.apartment_number
						? formData.drop_off.apartment_number
						: null,
					city: formData.drop_off.city,
					state: formData.drop_off.state,
					zip: formData.drop_off.zip,
					geocoded_address: formData.drop_off.geocoded_address,
					height_id: parseInt(formData.drop_off.height.value, 10),
					certificate_of_insurance_required: !!formData.drop_off.coi,
				};
				if (has(details, 'drop_off')) {
					if (formData.drop_off.lat !== details.drop_off.latitude)
						payload.drop_off.lat = formData.drop_off.lat;
					else payload.drop_off.latitude = details.drop_off.latitude;
					if (formData.drop_off.lng !== details.drop_off.longitude)
						payload.drop_off.lng = formData.drop_off.lng;
					else payload.drop_off.longitude = details.drop_off.longitude;
				} else {
					payload.drop_off.lat = formData.drop_off.lat;
					payload.drop_off.lng = formData.drop_off.lng;
				}
			}
			if (!!formData.extra_pick_up_enabled) {
				payload.extra_pick_up = {
					street_address: formData.extra_pick_up.street_address,
					apartment_number: !!formData.extra_pick_up.apt
						? formData.extra_pick_up.apt
						: null,
					city: formData.extra_pick_up.city,
					state: formData.extra_pick_up.state,
					zip: formData.extra_pick_up.zip,
					geocoded_address: formData.extra_pick_up.geocoded_address,
					height_id: parseInt(formData.extra_pick_up.height.value, 10),
					certificate_of_insurance_required: !!formData.extra_pick_up.coi,
					latitude: `${formData.extra_pick_up.lat}`,
					longitude: `${formData.extra_pick_up.lng}`,
				};
				if (!!formData.extra_pick_up.elevator) {
					payload.extra_pick_up.elevator_start_time =
						details.extra_pick_up.elevator_start_time;
					payload.extra_pick_up.elevator_end_time =
						details.extra_pick_up.elevator_end_time;
				}
				if (!!formData.extra_pick_up.coi) {
					payload.extra_pick_up.management_contact_name =
						details.extra_pick_up.management_contact_name;
					payload.extra_pick_up.management_phone_number =
						details.extra_pick_up.management_phone_number;
				} else {
					dispatch(
						setCoiInfo({
							addressKey: 'extra_pick_up',
							certificate_of_insurance_required: false,
							management_contact_name: null,
							management_phone_number: null,
						})
					);
				}
			} else {
				delete payload.extra_pick_up;
			}

			if (formData.extra_drop_off_enabled) {
				payload.extra_drop_off = {
					street_address: formData.extra_drop_off.street_address,
					apartment_number: !!formData.extra_drop_off.apt
						? formData.extra_drop_off.apt
						: null,
					city: formData.extra_drop_off.city,
					state: formData.extra_drop_off.state,
					zip: formData.extra_drop_off.zip,
					geocoded_address: formData.extra_drop_off.geocoded_address,
					height_id: parseInt(formData.extra_drop_off.height.value, 10),
					certificate_of_insurance_required: !!formData.extra_drop_off.coi,
					latitude: `${formData.extra_drop_off.lat}`,
					longitude: `${formData.extra_drop_off.lng}`,
				};
				if (!!formData.extra_drop_off.elevator) {
					payload.extra_drop_off.elevator_start_time =
						details.extra_drop_off.elevator_start_time;
					payload.extra_drop_off.elevator_end_time =
						details.extra_drop_off.elevator_end_time;
				}
				if (!!formData.extra_drop_off.coi) {
					payload.extra_drop_off.management_contact_name =
						details.extra_drop_off.management_contact_name;
					payload.extra_drop_off.management_phone_number =
						details.extra_drop_off.management_phone_number;
				} else {
					dispatch(
						setCoiInfo({
							addressKey: 'extra_pick_up',
							certificate_of_insurance_required: false,
							management_contact_name: null,
							management_phone_number: null,
						})
					);
				}
			} else {
				delete payload.extra_drop_off;
			}

			if (formData.user_note !== getState().movePlan.currentPlan.user_note)
				payload.user_note = formData.user_note;
			if (!!formData.contact_phone_number)
				payload.contact_phone_number = formData.contact_phone_number;

			if (!!formData.pick_up.elevator) {
				payload.pick_up.elevator_start_time =
					details.pick_up.elevator_start_time;
				payload.pick_up.elevator_end_time = details.pick_up.elevator_end_time;
			}
			if (!!formData.drop_off.elevator) {
				payload.drop_off.elevator_start_time =
					details.drop_off.elevator_start_time;
				payload.drop_off.elevator_end_time = details.drop_off.elevator_end_time;
			}
			if (!!formData.pick_up.coi) {
				payload.pick_up.management_contact_name =
					details.pick_up.management_contact_name;
				payload.pick_up.management_phone_number =
					details.pick_up.management_phone_number;
			}
			if (!!formData.drop_off.coi) {
				payload.drop_off.management_contact_name =
					details.drop_off.management_contact_name;
				payload.drop_off.management_phone_number =
					details.drop_off.management_phone_number;
			}
			if (formData.storage_enabled) {
				if (formData.storage_type.storage_in_transit.value) {
					payload.storage_move_out_date = format(
						formData.storage_move_out_date,
						'MM/DD/YYYY'
					);
					payload.warehouse_destination = false;
				} else {
					delete payload.drop_off;
					delete payload.storage_move_out_date;
					payload.warehouse_destination = true;
				}
				payload.move_into_storage =
					formData.storage_type.move_into_storage.value;
				payload.warehouse_destination =
					formData.storage_type.move_into_storage.value;
				payload.storage_in_transit =
					formData.storage_type.storage_in_transit.value;
			} else {
				payload.move_into_storage = false;
				payload.storage_in_transit = false;
				payload.storage_move_out_date = '';
				payload.warehouse_destination = false;
			}
			if (
				!!details['pick_up'].certificate_of_insurance_required !==
				formData['pick_up'].coi
			) {
				dispatch(
					setCoiInfo({
						addressKey: 'pick_up',
						certificate_of_insurance_required: formData['pick_up'].coi,
						management_contact_name:
							formData['pick_up'].management_contact_name,
						management_phone_number:
							formData['pick_up'].management_phone_number,
					})
				);
			}
			if (!!details['drop_off'] && formData['drop_off'].coi) {
				if (
					details['drop_off'].certificate_of_insurance_required !==
					formData['drop_off'].coi
				) {
					dispatch(
						setCoiInfo({
							addressKey: 'drop_off',
							certificate_of_insurance_required: formData['drop_off'].coi,
							management_contact_name:
								formData['drop_off'].management_contact_name,
							management_phone_number:
								formData['drop_off'].management_phone_number,
						})
					);
				}
			}
			if (!!formData.first_available_date) {
				payload.first_available_date = format(
					formData.first_available_date,
					'MM/DD/YYYY'
				);
			}
			dispatch(
				setDummyPayloadForMovePlan({
					address_update: true,
					email_lead: formData.email_lead,
					move_plan: {
						...payload,
						contact_email_address: formData.email_lead,
					},
				})
			);
			dispatch(
				updateMovePlanModal(
					{
						address_update: true,
						email_lead: formData.email_lead,
						move_plan: {
							...payload,
							contact_email_address: formData.email_lead,
						},
					},
					true
				)
			);
			if (!getState().funnel.progressBarSteps.DETAILS.isCompleted)
				dispatch(setStepCompleted('DETAILS'));
			dispatch(setDetailsLoading(false));
		}
	};

export default detailsMiddleware;
