import { useEffect } from 'react';

const initAnalytics = () => {
	let analytics = (window.analytics = window.analytics || []);
	if (!analytics.initialize)
		if (analytics.invoked)
			window.console &&
				console.error &&
				console.error('Segment snippet included twice.');
		else {
			analytics.invoked = !0;
			analytics.methods = [
				'trackSubmit',
				'trackClick',
				'trackLink',
				'trackForm',
				'pageview',
				'identify',
				'reset',
				'group',
				'track',
				'ready',
				'alias',
				'debug',
				'page',
				'once',
				'off',
				'on',
			];
			analytics.factory = function (t) {
				return function () {
					let e = Array.prototype.slice.call(arguments);
					e.unshift(t);
					analytics.push(e);
					return analytics;
				};
			};
			for (let t = 0; t < analytics.methods.length; t++) {
				let e = analytics.methods[t];
				analytics[e] = analytics.factory(e);
			}
			analytics.load = function (t, e) {
				let n = document.createElement('script');
				n.type = 'text/javascript';
				n.async = !0;
				n.src =
					'https://cdn.segment.com/analytics.js/v1/' + t + '/analytics.min.js';
				let a = document.getElementsByTagName('script')[0];
				a.parentNode.insertBefore(n, a);
				analytics._loadOptions = e;
			};
			analytics.SNIPPET_VERSION = '4.1.0';
			analytics.load(process.env.REACT_APP_SEGMENT_KEY);
			if (window.location.pathname.includes('/moving/')) {
				let temp = window.location.pathname.split('/');
				let path = '/' + temp[1] + '/' + temp[3];
				analytics.page(temp[3], { path: path });
			} else
				analytics.page(window.location.pathname, {
					path: window.location.pathname,
				});
		}
};

const AnalyticsWrapper = props => {
	useEffect(() => {
		if (window.location.pathname.includes('affiliates-widget')) return;
		let t = setTimeout(() => (window.onload = initAnalytics()), 5000);
		return () => clearTimeout(t);
	}, []);
	return props.children;
};

export default AnalyticsWrapper;
