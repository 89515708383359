import {
	GET_UTM_PARAMS,
	NETWORK_FAILURE,
	TRACK_TO_SEGMENT,
} from '../actionTypes';
import { setUtmParams, track } from '../actions/analytics';
import { setCouponData } from '../actions/analytics';
import { LOCATION_CHANGE } from 'connected-react-router';

const analyticsMiddleware =
	({ getState, dispatch }) =>
	next =>
	action => {
		next(action);
		if (action.type === GET_UTM_PARAMS) {
			if (getState().router.location.search.length > 0) {
				let params = JSON.parse(
					'{"' +
						decodeURI(getState().router.location.search.substring(1))
							.replace(/"/g, '\\"')
							.replace(/&/g, '","')
							.replace(/=/g, '":"') +
						'"}'
				);
				dispatch(setUtmParams(params));
				if (!!params.gclid) {
					dispatch(track('Landed from Google paid', { gclid: params.gclid }));
				}
				if (!!params.fcblid) {
					dispatch(
						track('Landed from Facebook paid', { fcblid: params.fcblid })
					);
				}
				if (!!params.promo) {
					dispatch(setCouponData({ code: params.promo }));
				}
			}
		}
		if (action.type === LOCATION_CHANGE) {
			if (!!window.analytics) {
				if (window.location.pathname.includes('/moving/')) {
					let temp = window.location.pathname.split('/');
					let path = '/' + temp[1] + '/' + temp[3];
					window.analytics.page(temp[3], { path: path });
				} else window.analytics.page(window.location.pathname);
			}
		}
		if (action.type === NETWORK_FAILURE) {
			if (!!window.analytics) {
				window.analytics.track('NETWORK FAILURE', action);
			}
		}
		if (action.type === TRACK_TO_SEGMENT) {
			if (!!window.analytics) {
				window.analytics.track(action.payload.event, action.payload.data);
			}
		}
	};

export default analyticsMiddleware;
